import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText
} from "@mui/material";
import {useUser, w2d2dApiClient} from "../../api-client";
import AvatarWidget from "../user/AvatarWidget";
import {Delete} from "@mui/icons-material";
import {useCallback, useState} from "react";

interface ConnectionEntryProps {
    userId: string;
    onDelete: (connectionId: string) => void;
}

export default function ConnectionEntry(props: ConnectionEntryProps) {

    const userHook = useUser(props.userId)

    const [connectionToDelete, setConnectionToDelete] = useState<null | string>(null)

    const handleClickDelete = useCallback(() => {
        setConnectionToDelete(props.userId)
    }, [setConnectionToDelete])

    const handleClickOk = useCallback(async () => {
        if (!connectionToDelete) {
            return
        }
        try {
            await w2d2dApiClient.deleteConnection({connectionId: connectionToDelete})
        } finally {
            setConnectionToDelete(null)
        }
        props.onDelete(connectionToDelete)
    }, [connectionToDelete, setConnectionToDelete])

    const handleClickCancel = useCallback(() => {
        setConnectionToDelete(null)
    }, [setConnectionToDelete])

    return (
        <>
            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={handleClickDelete}>
                        <Delete/>
                    </IconButton>
                }
                sx={{alignItems: 'center'}}
            >
                <ListItemAvatar>
                    <AvatarWidget id={props.userId}/>
                </ListItemAvatar>
                <ListItemText
                    primary={userHook.data?.name}
                    sx={{marginLeft: '8px'}}
                />
            </ListItem>

            <Dialog
                open={connectionToDelete !== null}
                onClose={handleClickCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`${userHook.data?.name} wirklich entfernen?`}
                </DialogTitle>
                {/*<DialogContent>*/}
                {/*    <DialogContentText id="alert-dialog-description">*/}
                {/*        `Möchtest Du die Connection zu ${userHook.data?.name} wirklich entfernen?`*/}
                {/*    </DialogContentText>*/}
                {/*</DialogContent>*/}
                <DialogActions>
                    <Button onClick={handleClickCancel} autoFocus>Abbrechen</Button>
                    <Button onClick={handleClickOk}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
