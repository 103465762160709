import {useContext, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import Session from "../auth/Session";
import {SessionContext} from "../auth/SessionProvider";
import SplashScreen from "../ui/SplashScreen";
import W2d2dRoutes from "../W2d2dRoutes";

export default function RootRoute() {

    const session = useContext<undefined | "NO_SESSION" | Session>(SessionContext)
    const navigate = useNavigate()

    useEffect(() => {
        console.log("RootRoute: useEffect called", session)
        if (session && session !== "NO_SESSION") {
            // Session is initialized, and we have a logged-in user route to "/ideas" as central the entry point
            console.log("Session initialized, logged in ==> redirect to ideas page")
            navigate(W2d2dRoutes.ideas)
        }
    }, [session])

    return <SplashScreen isLoading={session === undefined}/>
}
