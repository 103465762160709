import {User} from "../api-client";

export default class Session {

    readonly id: string;

    readonly user: User;

    logout(): void {
    }

    constructor(
        params: {
            id: string,
            user: User,
            logout: () => void,
        }
    ) {
        this.id = params.id
        this.user = params.user
        let l = params?.logout
        if (l) {
            this.logout = l
        }
    }
}
