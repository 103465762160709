import {Box, Button} from "@mui/material";
import {Google} from "@mui/icons-material";
import {w2d2dApiClient} from "../api-client";

interface SplashScreenProps {
    isLoading?: boolean
}

export default function SplashScreen({isLoading}: SplashScreenProps) {
    const href = w2d2dApiClient.apiBaseUrl + "auth/google/oauth/start"

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#000',
            }}
        >
            <Box
                component="img"
                src="media/w2d2d-splash.png"
                alt="Logo"
                sx={{
                    width: '400px',
                    marginBottom: '0.5rem',
                }}
            />

            {
                !isLoading && (

                    <Button
                        href={href}
                        variant="contained"
                        startIcon={<Google/>}
                        sx={{
                            // We should use official Google colors
                            backgroundColor: '#4285F4',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#357ae8',
                            },
                            padding: '0.75rem',
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                            borderRadius: '8px',
                        }}
                    >
                        Mit Google anmelden
                    </Button>
                )
            }
        </Box>
    )
}
