
class _W2d2dRoutes {

    readonly _ROOT = "/"

    readonly connections = "/connections"

    readonly ideas = "/ideas"
    toIdea(ideaId: string) { return this.ideas + "/" + ideaId }
    readonly ideas_add = this.ideas + "/add"

    readonly invitations = "/invitations"
    toInvitation(invitationId: string) { return this.invitations + "/" + invitationId }
}

let W2d2dRoutes = new _W2d2dRoutes()
export default W2d2dRoutes
