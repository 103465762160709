import {BottomNavigation, BottomNavigationAction, Box, Paper} from "@mui/material";
import React, {useCallback} from "react";
import Session from "../auth/Session";
import {Logout, OnlinePrediction, People} from "@mui/icons-material";
import {Outlet, useNavigate} from "react-router-dom";
import W2d2dRoutes from "../W2d2dRoutes";

interface AppProps {
    session: Session,
    section: "ideas" | "connections"
}

export default function App(props: AppProps) {

    const navigate = useNavigate()
    const [menuValue, setMenuValue] = React.useState(props.section)

    const handleConnectionsClick = useCallback(() => {
        navigate(W2d2dRoutes.connections)
    }, [])

    const handleIdeasClick = useCallback(() => {
        navigate(W2d2dRoutes.ideas)
    }, [])

    const handleLogoutClick = useCallback(() => {
        props.session.logout()
    }, [props.session])

    return (

        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Outlet/>
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={menuValue}
                    onChange={(_, newValue) => {
                        setMenuValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Ideen" value="ideas" icon={<OnlinePrediction/>} onClick={handleIdeasClick}/>
                    <BottomNavigationAction label="Freunde" value="connections" icon={<People/>} onClick={handleConnectionsClick}/>
                    <BottomNavigationAction
                        label="Abmelden"
                        value="logout"
                        icon={<Logout/>}
                        onClick={handleLogoutClick}
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    )
}
