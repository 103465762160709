import {w2d2dApiClient} from "../../api-client"
import {ChangeEvent, useCallback, useState} from "react"
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import W2d2dRoutes from "../../W2d2dRoutes";

interface IdeaDraft {
    title: string;
    startsAt: Date;
    duration: number;
}

const buildIdeaDraft = (): IdeaDraft => {
    let startsAt = new Date()
    if (startsAt.getHours() < 19) {
        startsAt.setHours(19, 0, 0, 0)
    } else {
        startsAt.setHours(startsAt.getHours(), 0, 0, 0)
    }
    return {
        title: "Zusammen abhängen",
        startsAt: startsAt,
        duration: 2,
    }
}

export default function AddIdeaPage() {

    const navigate = useNavigate()

    const [idea, setIdea] = useState<IdeaDraft>(buildIdeaDraft())

    const handleIdeaTitleInputValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let newTitle = e.target.value
        setIdea((idea) => ({...idea, title: newTitle}))
    }, [setIdea])

    const handleIdeaStartsAtChange = useCallback((newStartsAt: Date | null) => {
        if (newStartsAt == null) {
            // ignore null values
            return
        }
        setIdea((idea) => ({...idea, startsAt: newStartsAt}))
    }, [setIdea])

    const handleDurationChange = useCallback((event: SelectChangeEvent) => {
        let newDuration: number = +event.target.value
        setIdea((idea) => ({...idea, duration: newDuration}))
    }, [setIdea])

    const handleClickAdd = useCallback(async () => {
        await w2d2dApiClient.newIdea({
            title: idea.title,
            startsAt: idea.startsAt,
            endsAt: new Date(idea.startsAt.getTime() + 1000 * 60 * 60 * idea.duration),
        })
        navigate(W2d2dRoutes.ideas)
    }, [idea, navigate])

    return (
        <Container
            maxWidth="sm"
            sx={{
                marginTop: 8,
                paddingLeft: 5,
                paddingRight: 5,
            }}
        >

            <TextField
                autoFocus
                margin="normal"
                id="idea"
                label="Vorschlag"
                type="text"
                fullWidth
                required
                defaultValue={idea.title}
                variant="standard"

                onChange={handleIdeaTitleInputValueChange}
            />

            <Stack direction="row" spacing={3} sx={{ marginTop: 4 }}>

                <DateTimePicker
                    label="Wann"
                    value={idea.startsAt}
                    minDate={new Date()}

                    onChange={handleIdeaStartsAtChange}
                    views={['month', 'day', 'hours', 'minutes']}

                    // slotProps={{textField: { variant: "standard" }}}
                    // sx={{ minWidth: 120 }}
                />

                <FormControl
                    // fullWidth
                    // sx={{ minWidth: 120 }}
                >
                    <InputLabel id="duration-select-label">Dauer</InputLabel>
                    <Select
                        id="duration-select"
                        value={`${idea.duration}`}
                        onChange={handleDurationChange}
                        label="Dauer"
                        // variant="standard"
                    >
                        <MenuItem value={1}>1 Stunde</MenuItem>
                        <MenuItem value={2}>2 Stunden</MenuItem>
                        <MenuItem value={3}>3 Stunden</MenuItem>
                        <MenuItem value={4}>4 Stunden</MenuItem>
                        <MenuItem value={5}>5 Stunden</MenuItem>
                        <MenuItem value={6}>6 Stunden</MenuItem>
                    </Select>
                </FormControl>
            </Stack>

            <Box sx={{marginTop: 4, paddingRight: 2, textAlign: "right"}}>
                <Button disabled={idea.title.trim().length == 0} onClick={handleClickAdd}>Erstellen</Button>
            </Box>
        </Container>
    )
}
