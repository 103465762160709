import {useNavigate, useParams} from "react-router-dom";
import {useIdea, w2d2dApiClient} from "../../api-client";
import {formatDateTime, formatTime} from "@eventer/api-client/lib/esm/utils/datetime-util";
import AvatarWidget from "../user/AvatarWidget";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText
} from "@mui/material";
import {useCallback, useState} from "react";
import W2d2dRoutes from "../../W2d2dRoutes";
import {Delete} from "@mui/icons-material";
import Session from "../../auth/Session";

interface IdeaPageProps {
    readonly session: Session
}

export default function IdeaPage(props: IdeaPageProps) {

    const {id} = useParams()
    const navigate = useNavigate()

    const ideaHook = useIdea(id!!)

    const [openDialog, setOpenDialog] = useState(false)

    // TODO: idea must be given
    // const authorHook = useUser(idea.authorId)

    const handleClickCancel = useCallback(async () => {
        setOpenDialog(false)
    }, [setOpenDialog])

    const handleClickRemove = useCallback(async () => {
        setOpenDialog(true)
    }, [setOpenDialog])

    const onDelete = useCallback(async () => {
        if (!id) {
            return
        }
        await w2d2dApiClient.deleteIdea({id: id})
        navigate(W2d2dRoutes.ideas)
    }, [id, navigate])


    const idea = ideaHook.data
    if (!idea) {
        return
    }

    let isActive = idea.startsAt && new Date(idea.startsAt) < new Date() && (!idea.endsAt || new Date(idea.endsAt) > new Date())

    let startsAt = null
    if (idea.startsAt) {
        startsAt = new Date(idea.startsAt)
    }
    let endsAt = null
    if (idea.endsAt) {
        endsAt = new Date(idea.endsAt)
    }

    let startEndAtSameDay = startsAt && endsAt && startsAt.getDate() === endsAt.getDate()

    let footerText = ""
    let hasStartsAt = false
    if (!isActive && startsAt) {
        hasStartsAt = true

        let timeSuffix = undefined
        if (startEndAtSameDay) {
            timeSuffix = null
        }

        let startsToday = startsAt.getDate() === new Date().getDate()
        if (startsToday) {
            footerText += formatTime({date: startsAt, timeSuffix: timeSuffix})
        } else {
            footerText += formatDateTime({date: startsAt})
        }
    }
    if (endsAt) {
        if (hasStartsAt) {
            footerText += " - "
        } else {
            footerText += " bis "
        }
        if (startEndAtSameDay) {
            footerText += formatTime({date: new Date(endsAt)})
        } else {
            footerText += formatDateTime({date: new Date(endsAt)})
        }
    }

    let action
    if (idea.authorId === props.session.user.id) {
        action =
            (<IconButton edge="end" aria-label="delete" onClick={handleClickRemove}>
                    <Delete/>
                </IconButton>
            )
    }

    return (
        <>
            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    action
                }
                sx={{alignItems: 'center'}}
            >
                <ListItemAvatar>
                    <AvatarWidget id={idea.authorId} size={64}/>
                </ListItemAvatar>
                <ListItemText
                    primary={idea.title}
                    secondary={
                        <>
                            {/*<Typography*/}
                            {/*    component="span"*/}
                            {/*    variant="body2"*/}
                            {/*    sx={{color: 'text.primary', display: 'inline'}}*/}
                            {/*>*/}
                            {/*    {authorHook.data?.name}*/}
                            {/*</Typography>*/}
                            {footerText}
                        </>
                    }
                    sx={{marginLeft: '8px'}}
                />
            </ListItem>

            <Dialog
                open={openDialog}
                onClose={handleClickCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Idee wirklich löschen?`}
                </DialogTitle>
                {/*<DialogContent>*/}
                {/*    <DialogContentText id="alert-dialog-description">*/}
                {/*        `Möchtest Du die Connection zu ${userHook.data?.name} wirklich entfernen?`*/}
                {/*    </DialogContentText>*/}
                {/*</DialogContent>*/}
                <DialogActions>
                    <Button onClick={handleClickCancel} autoFocus>Abbrechen</Button>
                    <Button onClick={onDelete}>
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
