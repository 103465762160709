import {useNavigate, useParams} from "react-router-dom"
import {useCallback, useEffect, useState} from "react"
import {Invitation, w2d2dApiClient} from "../../api-client"
import {Box, Button, Container, IconButton, Snackbar, Stack, Typography} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";
import W2d2dRoutes from "../../W2d2dRoutes";
import Session from "../../auth/Session";
import AvatarWidget from "../user/AvatarWidget";

interface InvitationPageProps {
    readonly session: Session
}

export default function InvitationPage(props: InvitationPageProps) {

    const navigate = useNavigate()

    const {id} = useParams()

    const [invitation, setInvitation] = useState<null | "NOT_FOUND" | Invitation>(null)

    const [openHint, setOpenHint] = useState(false)

    let handleClick = useCallback(() => {
        if (invitation == null || invitation === "NOT_FOUND") {
            return
        }
        let link = w2d2dApiClient.appBaseUrl + 'invitations/' + invitation.id
        navigator.clipboard.writeText(link)
        setOpenHint(true)
    }, [invitation, setOpenHint])

    const handleClose = () => {
        setOpenHint(false)
    }

    const handleClickAccept = useCallback(async () => {
        if (invitation == null || invitation === "NOT_FOUND") {
            return
        }
        await w2d2dApiClient.acceptInvitation({invitationId: invitation.id})
        navigate(W2d2dRoutes.connections)
    }, [invitation])

    const handleClickCancel = useCallback(async () => {
        navigate(W2d2dRoutes.connections)
    }, [])

    useEffect(() => {
        let active = true
        if (id) {
            w2d2dApiClient.getInvitation({invitationId: id})
                .then((invitation) => {
                    if (!active) {
                        return
                    }
                    setInvitation(invitation)
                })
                .catch(() => {
                    // happens on 404
                    if (!active) {
                        return
                    }
                    setInvitation("NOT_FOUND")
                })
        } else {
            // should not happen
            setInvitation("NOT_FOUND")
        }
        return () => { active = false }
    }, [id, setInvitation]);

    let el
    if (invitation === null) {

        // still loading
        return
    }

    if (invitation === "NOT_FOUND") {

        // not found
        el = (
            <Typography>
                Diese Einladung ist nicht mehr gültig.
            </Typography>
        )

    } else if (props.session.user.id === invitation.inviterId) {

        // invitation was created by the current user
        let link = w2d2dApiClient.appBaseUrl + 'invitations/' + invitation.id
        el = (
            <>
                <Typography variant="h5">
                    Hier ist der Link zu Deiner Einladung
                </Typography>
                <Stack
                    key={`${invitation.id}-image-photographer`}
                    direction="row"
                    padding={2}
                >
                    <Box>
                        <Snackbar
                            open={openHint}
                            autoHideDuration={4000}
                            onClose={handleClose}
                            message={`Der Link wurde in die Zwischenablage kopiert.`}
                        />
                        <IconButton
                            onClick={handleClick}
                        >
                            <ContentCopy
                                cursor="pointer"
                                sx={{
                                    height: "14px",
                                    width: "14px",
                                }}
                            />
                        </IconButton>
                    </Box>
                    <Typography variant="subtitle1">
                        {link}
                    </Typography>
                </Stack>
            </>
        )
    } else {

        // invitation was created by another user
        el = (
            <>
            <AvatarWidget id={invitation.inviterId} size={64}/>
            <Typography
                variant="h6"
                sx={{
                    marginTop: 2,
                }}
            >
               Möchte sich mit Dir verbinden
            </Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Button
                        onClick={handleClickAccept}
                    >
                        Akzeptieren
                    </Button>
                    <Button
                        onClick={handleClickCancel}
                    >
                        Ablehnen
                    </Button>
                </Stack>
            </>
        )
    }

    return (
        <Container
            sx={{
                marginTop: 4,
            }}
        >
            {el}
        </Container>
    )
}
